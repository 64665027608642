<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 删除用户提示 -->
    <el-dialog
      v-dialogDrag
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title">删除商品</div>
      </div>
      确认删除商品吗？
      <div style="margin: 10px 0">商品名称：{{ userdelinfo.name }}</div>
      <!-- <el-cascader
        placeholder="关联任务"
        size="mini"
        :options="optionsTask"
        :props="optionPropsTask"
        v-model="delForm.task_id"
        filterable
      ></el-cascader> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>商品列表</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="10">
              <el-button type="primary" @click="addCommodityOpen()"
                >添加商品</el-button
              >

              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
            <el-col :span="9">
              <div class="border">
                <div
                  @click="changeItem(item)"
                  v-for="item in list"
                  :key="item.name"
                  class="border-item"
                  :class="[item.id == getInfo.differentiate ? 'active' : '']"
                >
                  {{ item.name }}
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            ref="multipleTable"
            :data="tableData"
            class="commoTable"
            tooltip-effect="dark"
            style="width: 100%"
            :row-class-name="getRowClassName"
          >
            <el-table-column type="expand">
              <template v-slot="scope">
                <el-tag
                  style="margin-right:5px"
                  v-for="item in scope.row.spec"
                  :key="item.id"
                  >{{ item.key_name }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="img" label="商品图" width="120">
              <template slot-scope="scope">
                <el-image :src="scope.row.img" style="width: 80px"></el-image>
              </template>
            </el-table-column>
            <el-table-column
              prop="numbering"
              label="商品编号"
              width="120"
            ></el-table-column>
            <!-- <el-table-column
              prop="for_short"
              label="商品简称"
              width="100"
            ></el-table-column> -->
            <el-table-column
              prop="add_time"
              label="添加时间"
              width="110"
            ></el-table-column>
            <!-- <el-table-column
              prop="price"
              label="采购价格"
              width="100"
            ></el-table-column> -->
            <el-table-column label="商品状态" width="100">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState(scope.row)"
                  v-model="scope.row.state"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="2"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column
              prop="class_name"
              label="分类名称"
              width="100"
            ></el-table-column>
            <el-table-column
              prop="v_name"
              label="供应商"
              width="100"
            ></el-table-column>
            <el-table-column width="200" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="openEdit(scope.row)"
                >
                  编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                >
                  删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :pager-count="15"
            :page-size="this.getInfo.page_num"
            background
            :total="exp.num"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>

    <!-- 添加/编辑商品对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="commodityDialogVisible"
      :before-close="formClose"
    >
      <div>
        <!-- tabs -->
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
          <el-tab-pane label="基础信息" name="1">
            <el-form
              :rules="cmdFormRules"
              ref="commodityFormRef"
              :model="commodityForm"
              size="small"
              label-position="left"
            >
              <el-form-item
                label="商品名称"
                :label-width="formLabelWidth"
                prop="name"
              >
                <el-input
                  v-model="commodityForm.name"
                  placeholder="请输入商品名称"
                  style="width: 50%; margin-right: 10px"
                ></el-input>
                <el-input
                  v-model="commodityForm.for_short"
                  placeholder="请输入商品简称"
                  style="width: 30%"
                >
                </el-input>
              </el-form-item>

              <el-form-item
                prop="accessories"
                label="有无配件"
                :label-width="formLabelWidth"
              >
                <el-radio v-model="commodityForm.accessories" :label="0"
                  >无</el-radio
                >
                <el-radio v-model="commodityForm.accessories" :label="1"
                  >有</el-radio
                >
              </el-form-item>
              <el-form-item
                prop="consumable"
                label="易耗品"
                :label-width="formLabelWidth"
              >
                <el-radio v-model="commodityForm.consumable" :label="0"
                  >否</el-radio
                >
                <el-radio v-model="commodityForm.consumable" :label="1"
                  >是</el-radio
                >
              </el-form-item>
              <el-form-item
                prop="differentiate"
                label="选择产品类型"
                :label-width="formLabelWidth"
              >
                <el-radio v-model="commodityForm.differentiate" :label="2"
                  >用品</el-radio
                >
                <el-radio v-model="commodityForm.differentiate" :label="1"
                  >食品</el-radio
                >
              </el-form-item>

              <el-form-item
                prop="v_id"
                label="供应商"
                :label-width="formLabelWidth"
              >
                <el-select
                  v-model="commodityForm.v_id"
                  style="width: 100%"
                  placeholder="请选择供应商"
                  clearable
                >
                  <el-option
                    v-for="item in supplier"
                    :key="item.id"
                    :label="item.dealer_name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="type_id"
                label="核算方式"
                :label-width="formLabelWidth"
              >
                <el-select
                  v-model="commodityForm.type_id"
                  style="width: 100%"
                  placeholder="请选择核算方式"
                  clearable
                >
                  <el-option
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                prop="class_id"
                label="商品分类"
                :label-width="formLabelWidth"
              >
                <el-cascader
                  placeholder="请选择商品分类"
                  :options="classifyOpt"
                  :props="optionProps"
                  v-model="commodityForm.class_id"
                  filterable
                  clearable
                >
                </el-cascader>
              </el-form-item>
              <el-form-item
                prop="detail"
                label="商品描述"
                :label-width="formLabelWidth"
              >
                <el-input
                  placeholder="请输入用于展示的商品描述"
                  v-model="commodityForm.detail"
                ></el-input>
              </el-form-item>
              <el-form-item
                prop="meal"
                :label-width="formLabelWidth"
                label="设置套餐"
              >
                <div
                  style="display:inline-block"
                  v-for="(item, index) in commodityForm.meal"
                  :key="index"
                >
                  <el-input
                    @input="handleIptChange()"
                    v-model="item.name"
                    placeholder="套餐名"
                    style="width:100px"
                  ></el-input>
                  <el-input
                    @input="handleIptChange()"
                    v-model="item.amount"
                    type="number"
                    min="1"
                    placeholder="数量"
                    style="width:80px;margin:0 5px"
                  ></el-input>
                </div>
                <span
                  @click="addNewInput()"
                  style="color:#409eff;cursor: pointer"
                  >增加
                </span>
                <span
                  @click="delLastInput()"
                  style="color:#f56c6c;cursor: pointer"
                  >删除</span
                >
              </el-form-item>
              <!-- <el-form-item
                prop="sort"
                label="排序"
                :label-width="formLabelWidth"
              >
                <el-input
                  placeholder="请输入排序"
                  type="number"
                  :min="1"
                  v-model="commodityForm.sort"
                ></el-input>
              </el-form-item> -->
              <el-form-item
                label="出餐时间(分钟)"
                prop="meal_time"
                :label-width="formLabelWidth"
              >
                <el-input
                  v-model="commodityForm.meal_time"
                  type="number"
                  min="1"
                ></el-input>
              </el-form-item>
              <el-form-item label="关联任务" :label-width="formLabelWidth">
                <el-cascader
                  placeholder="选择关联任务"
                  :options="optionsTask"
                  :props="optionPropsTask"
                  v-model="commodityForm.task_id"
                  filterable
                ></el-cascader>
              </el-form-item>
            </el-form>

            <div class="btns">
              <el-button type="success" @click="nextStep">下一步</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="图片信息" name="2">
            <el-upload
              :headers="token"
              accept=".jpg,.png,.jpeg"
              :on-remove="imgRemove"
              :limit="1"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess"
              name="image"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">上传商品图</el-button>
            </el-upload>
            <el-upload
              style="margin: 40px 0"
              :headers="token"
              accept=".jpg,.png,.jpeg"
              :on-remove="imgRemove2"
              :limit="10"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess2"
              name="image"
              :file-list="fileList2"
            >
              <el-button size="small" type="primary">上传详情图</el-button>
            </el-upload>
            <el-upload
              :headers="token"
              accept=".jpg,.png,.jpeg"
              :on-remove="imgRemove3"
              :limit="10"
              :action="imgUrl"
              list-type="picture"
              :on-exceed="exceed"
              :on-success="imgSuccess3"
              name="image"
              :file-list="fileList3"
            >
              <el-button size="small" type="primary">上传商品轮播图</el-button>
            </el-upload>
            <div class="btns">
              <el-button type="primary" @click="prevStep">上一步</el-button>
              <el-button type="success" @click="nextStep">下一步</el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane label="规格信息" name="3">
            <el-form :model="commodityForm">
              <el-form-item label="规格类型" :label-width="formLabelWidth">
                <el-select
                  @change="getSpecSize()"
                  v-model="modelSpecSize.modelId"
                  style="width: 100%"
                  placeholder="请选择规格类型"
                  clearable
                >
                  <el-option
                    v-for="item in modelList"
                    :key="item.type_id"
                    :label="item.name"
                    :value="item.type_id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <div class="spec-checkbox">
                <el-form-item
                  v-for="item in specSizeData"
                  :label-width="formLabelWidth"
                  :key="item.id"
                  :label="item.name"
                >
                  <!-- <el-checkbox-group v-model="checkList" @change="handleSpecChange"> -->
                  <el-checkbox-group v-model="checkList">
                    <el-tag type="success" v-if="item.item.length === 0"
                      >该规格下暂时没有属性</el-tag
                    >
                    <el-checkbox
                      v-else
                      v-for="itm in item.item"
                      :key="itm.id"
                      :label="itm.id"
                      >{{ itm.item }}</el-checkbox
                    >
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item v-if="hasType" :label-width="formLabelWidth">
                  <el-button size="mini" type="primary" @click="onSubmit"
                    >获取规格属性表格</el-button
                  >
                  <el-button size="mini" @click="reset">重置表格</el-button>
                </el-form-item>
              </div>

              <el-table
                :key="isUpdate"
                class="commotable"
                :data="commodityForm.item"
              >
                <!-- <el-table-column prop="date" label="大小" width="150"></el-table-column>
                <el-table-column prop="date" label="颜色" width="150"></el-table-column> -->
                <el-table-column prop="spec" label="规格"> </el-table-column>
                <!-- <el-table-column prop="res_img" label="图片"></el-table-column> -->
                <el-table-column prop="length" label="体积">
                  <template slot-scope="scope">
                    <el-form-item>
                      <el-input
                        v-model="scope.row.length"
                        style="width:100px"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" label="重量">
                  <template slot-scope="scope">
                    <el-form-item>
                      <el-input
                        v-model="scope.row.weight"
                        type="number"
                        min="0"
                        style="width:100px"
                        size="mini"
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column width="200" label="图片">
                  <template slot-scope="scope">
                    <el-form-item>
                      <div
                        style="display: flex;align-items: center;justify-content: center;"
                      >
                        <el-upload
                          :headers="token"
                          accept=".jpg,.png,.jpeg"
                          :on-remove="imgRemove"
                          :limit="1"
                          :action="imgUrl"
                          :show-file-list="false"
                          list-type="picture"
                          :on-exceed="exceed"
                          :on-success="
                            (response) =>
                              specImgSuccess(
                                response,

                                scope.$index,
                                scope.row
                              )
                          "
                          name="image"
                        >
                          <img
                            style="margin-right: 5px;margin-top: 13px;"
                            v-if="
                              scope.row.spec_img &&
                                scope.row.spec_img.length !== 0
                            "
                            :src="scope.row.spec_img"
                            class="avatar"
                            width="80"
                          />

                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                          ></i>
                          <!-- <el-button
                          size="small"
                          type="primary"
                          @click="curRowIndex = scope.$index"
                          >上传商品图</el-button
                        > -->
                        </el-upload>
                        <span
                          v-if="
                            scope.row.spec_img &&
                              scope.row.spec_img.length !== 0
                          "
                          @click="delPic(scope.row, scope.$index)"
                          style="color:#f56c6c;cursor: pointer ;"
                          >删除</span
                        >
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column width="200" label="套餐">
                  <template slot-scope="scope">
                    <el-form-item>
                      <div
                        style="display:inline-block"
                        v-for="(item, index) in scope.row.meal"
                        :key="index"
                      >
                        <el-input
                          size="mini"
                          @input="handleIptChange(scope.row)"
                          v-model="scope.row.meal[index].name"
                          placeholder="套餐名"
                          style="width:70px"
                        ></el-input>
                        <el-input
                          size="mini"
                          @input="handleIptChange()"
                          v-model="scope.row.meal[index].amount"
                          type="number"
                          min="1"
                          placeholder="数量"
                          style="width:60px;margin:0 5px"
                        ></el-input>
                      </div>
                      <div>
                        <span
                          @click="tableAddNewInput(scope.row, scope.$index)"
                          style="color:#409eff;cursor: pointer"
                          >增加
                        </span>
                        <span
                          @click="tableDelLastInput(scope.row, scope.$index)"
                          style="color:#f56c6c;cursor: pointer"
                          >删除</span
                        >
                      </div>
                    </el-form-item>
                  </template>
                </el-table-column>
              </el-table>
            </el-form>

            <div class="btns">
              <el-button type="success" @click="prevStep">上一步</el-button>
              <el-button @click="cancelAE()">取 消</el-button>
              <el-button type="primary" @click="confirmAE()">确 认</el-button>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getState,
  getDel,
  getAdd,
  getOption,
  getOption2,
  addCommoditySpec,
  getCommoditySpec,
  editCommoditySpec,
} from '@/api/psi/commodity.js'
import { getList1 } from '@/api/psi/accountingmethods.js'
import { getModelList, getEditModelInfo } from '@/api/psi/commoditymodel.js'
import {
  getUserInfo,
  getWarehousesList,
  getGoodsList,
  getToday,
  getStorageNo,
  addGoodsToStorage,
  storageGoodsList,
  storageGoodsInfo,
  editStorageGoods,
} from '@/api/psi/storage.js'
import lodash from 'lodash'
import { isEmpty } from 'element-ui/src/utils/util'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      token: {
        'Accept-Token': window.sessionStorage.getItem('token'),
      },
      specSizeData: [],
      activeName: '1',
      cmdFormRules: {
        name: { required: true, message: '请输入商品名称', trigger: 'blur' },
        accessories: { required: true, message: '请选择是否有配件' },
        consumable: { required: true, message: '请选择是否易耗品' },
        v_id: { required: true, message: '请选择供应商名称' },
        class_id: { required: true, message: '请选择商品分类' },
        differentiate: { required: true, message: '请选择产品类型' },
        type_id: {
          required: true,
          message: '请选择核算方式',
        },
      },
      //供应商
      supplier: [],
      isUpdate: false,
      // 商品基础信息表单
      commodityForm: {
        meal_time: 1,
        name: '',
        type_id: '',
        for_short: '',
        spec_img: '',
        detail: '',
        // sort: '',
        info_img: [],
        accessories: 0,
        numbering: '123456',
        consumable: 0,
        v_id: '',
        detail: '',
        class_id: [],
        item: [],
        task_id: '',
        differentiate: 2,
        slideshow: [],
        set_meal: [],
        meal: [],
      },
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      classifyOpt: [],
      title: '添加商品',
      commodityDialogVisible: false,
      optionProps: {
        value: 'id',
        label: 'name',
        children: 'son',
      },
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      optionsTask: [],
      delForm: {
        task_id: '',
      },
      dialogLog: {
        state: false,
      },
      //   optionProps: {
      //     value: 'id',
      //     label: 'name',
      //     children: 'sub',
      //   },
      openState: true,
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
        differentiate: 2,
      },
      //导航
      nav: [],
      //上级列表
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {},
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
      list: [
        { id: 1, name: '食品' },
        { id: 2, name: '用品' },
      ],
      tableLoading: false,
      addOrEdit: 1,
      fileList: [],
      fileList2: [],
      fileList3: [],

      modelSpecSize: {
        modelId: '',
        specId: '',
        sizeId: '',
      },
      modelList: [],
      checkList: [],

      specTableData: [],

      filterSpec: [],
      multipleArr: [],
      multipleRenderArr: [],
      tempArr: [],
      specSizeDataCpyForTable: [],
      testArr: [],
      specIds: [],
      hasType: false,
      // 缓存编辑的规格数据
      storageItem: [],
      // 缓存编辑的CheckList
      storageCheckList: [],
      addDialogVisible: false,
      addForm: {},
      //供应商
      supplier: [],
      warehousesList: [],
      typeList: [],
      rowData: {},
    }
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
    computeMeal() {
      if (this.commodityForm.set_meal.length !== 0) {
        const temp = JSON.parse(JSON.stringify(this.commodityForm.set_meal))
        for (let i = 0; i < temp.length; i++) {
          const arr = temp[i].split(',')
          var res = []
          res.push({
            amount: arr[1],
            name: arr[0],
          })
        }
        return res
      }
    },
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    changeItem(item) {
      this.getInfo.differentiate = item.id
      this.catalogue()
    },
    dialogOpen() {
      this.dialogLog.state = true
    },
    //移除合同的事件
    imgRemove(file, fileList) {
      console.log('商品图删除')
      this.commodityForm.spec_img = ''
      this.fileList = []
    },
    //导入合同事件
    imgSuccess(response, file, fileList) {
      this.commodityForm.spec_img = response.data.url
      this.commodityForm.img = response.data.url
    },
    specImgSuccess(response, index, row) {
      console.log(row)
      console.log(this.commodityForm.item[index])
      console.log(response)
      //   this.tableRes[index].price_image = []
      this.$set(this.commodityForm.item[index], 'spec_img', '')
      if (response.code === 0) {
        //this.tableRes[index].price_image.push({ url: response.data.url })
        this.$set(this.commodityForm.item[index], 'spec_img', response.data.url)
        this.isUpdate = !this.isUpdate
        //  this.$set(this.tableRes, index, row)
      } else {
        this.$message.error(response.message)
      }

      this.$forceUpdate()
    },
    delPic(row, index) {
      this.$set(this.commodityForm.item[index], 'spec_img', '')
    },
    //导入图片事件
    imgSuccess2(response, file, fileList) {
      console.log(response)
      // this.commodityForm.info_img = response.data.url
      this.commodityForm.info_img.push(response.data.url)
    },

    //移除图片的事件
    imgRemove2(file, fileList) {
      console.log(fileList)
      //  this.commodityForm.info_img = ''
      //  this.fileList2 = []

      //   fileList.forEach((item) => {
      //     if (item.response) {
      //       this.commodityForm.info_img.push(item.response.data.url)
      //     } else {
      //       this.commodityForm.info_img.push(item.url)
      //     }
      //   })
      //   console.log(this.commodityForm)
      if (
        this.commodityForm.info_img &&
        this.commodityForm.info_img.length > 0
      ) {
        this.commodityForm.info_img.forEach((item, index) => {
          if (file.url == item) {
            console.log('sss')
            this.commodityForm.info_img.splice(index, 1)
          }
        })
      }
    },
    //导入图片事件
    imgSuccess3(response, file, fileList) {
      console.log(response)
      this.commodityForm.slideshow.push(response.data.url)
    },

    //移除图片的事件
    imgRemove3(file, fileList) {
      //   console.log(fileList)
      //   this.commodityForm.slideshow = []
      //   fileList.forEach((item) => {
      //     if (item.response) {
      //       this.commodityForm.slideshow.push(item.response.data.url)
      //     } else {
      //       this.commodityForm.slideshow.push(item.url)
      //     }
      //   })
      //   console.log(this.commodityForm)

      console.log(file)
      console.log(this.commodityForm.slideshow)
      if (
        this.commodityForm.slideshow &&
        this.commodityForm.slideshow.length > 0
      ) {
        this.commodityForm.slideshow.forEach((item, index) => {
          if (file.url == item) {
            console.log('sss')
            this.commodityForm.slideshow.splice(index, 1)
          }
        })
      }
    },
    // 获得供应商下拉菜单
    async getSupplier() {
      const { data: res } = await getOption2()
      const { data: res1 } = await getOption()
      const { data: res2 } = await getModelList()
      const { data: res3 } = await getList1()
      console.log(res2)
      this.supplier = res.data
      this.classifyOpt = res1.data
      this.modelList = res2.data
      this.typeList = res3.data
    },

    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.$router.push({
        path: 'addcommodity',
        query: {
          name: '增加商品',
        },
      })
    },
    prevStep() {
      if (this.activeName === '2') {
        this.activeName = '1'
      } else {
        this.activeName = '2'
      }
    },
    nextStep() {
      if (this.activeName === '1') {
        this.activeName = '2'
      } else {
        this.activeName = '3'
      }
    },

    //打开编辑
    editOpen(row) {
      this.$router.push({
        path: '/editcommodity',
        query: {
          id: row.id,
          name: '编辑商品',
        },
      })
    },
    async changeState(data) {
      let info = await getState({ id: data.id })
      this.judge(info)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    async openEdit(row) {
      console.log(row)
      this.rowData = row
      this.getSupplier()

      this.commodityDialogVisible = true
      this.title = '编辑商品'
      this.addOrEdit = 2
      const { data: res } = await getCommoditySpec({ mp_id: row.id })
      console.log(res)
      this.commodityForm = res.data.commodity_info
      delete this.commodityForm.id
      this.commodityForm.meal = []
      this.commodityForm.mp_id = row.id
      //   const slide = JSON.parse(this.commodityForm.slideshow)
      //   this.commodityForm.slideshow = slide
      //   console.log(slide)
      if (res.data.type_info.id) {
        this.modelSpecSize.modelId = res.data.type_info.id

        // console.log(res.data.type_info.spec_arr);
        // 获取规格信息 并处理  使大小颜色等正确勾选
        const specArr = res.data.goods_spec_list
        // console.log(specArr);
        if (specArr.length !== 0) {
          specArr.forEach((i) => {
            const key = i.key
            //console.log(key);
            const tempArr = key.split(',')
            //console.log(tempArr);
            this.checkList.push(...tempArr)

            this.checkList = Array.from(new Set(this.checkList))
          })
          for (let i = 0; i < this.checkList.length; i++) {
            this.checkList[i] = Number(this.checkList[i])
          }
          //  this.checkList = Array.from(new Set(this.checkList))
          this.storageCheckList = lodash.cloneDeep(this.checkList)
        }
        // 根据id获取规格和规格下的属性
        this.getSpecSize(res.data.type_info.id).then(() => {
          console.log('123')

          this.handleSpecChange(this.checkList)
          const specList = res.data.goods_spec_list
          this.hasType = true
          this.commodityForm.item = []
          if (specList.length !== 0) {
            specList.forEach((item) => {
              this.commodityForm.item.push({
                id: item.id,
                set_meal: item.set_meal || [],
                spec_img: item.spec_img,
                spec: item.key_name,
                length: item.length,
                weight: item.weight,
              })
            })
            this.commodityForm.item.forEach((itm) => {
              itm.meal = []
              itm.set_meal.forEach((set) => {
                let arr = set.split(',')
                itm.meal.push({
                  name: arr[0],
                  amount: arr[1],
                })
              })
            })
          }
        })

        // 处理表格回显
      }
      if (
        this.commodityForm.class_id &&
        this.commodityForm.class_id.length !== 0
      ) {
        this.commodityForm.class_id = this.commodityForm.class_id.split(',')

        const temp = JSON.parse(JSON.stringify(this.commodityForm.class_id))
        // temp.map(Number)
        // console.log(temp);
        for (let i = 0; i < temp.length; i++) {
          temp[i] = Number(temp[i])
          // console.log(temp[i]);
        }

        this.commodityForm.class_id = temp
      }
      // 处理套餐回显
      if (
        this.commodityForm.set_meal &&
        this.commodityForm.set_meal.length !== 0
      ) {
        const temp = JSON.parse(JSON.stringify(this.commodityForm.set_meal))
        for (let i = 0; i < temp.length; i++) {
          const arr = temp[i].split(',')

          this.commodityForm.meal.push({
            amount: arr[1],
            name: arr[0],
          })
        }
      }
      // 处理图片信息回显
      if (this.commodityForm.img !== '') {
        this.fileList.push({ url: this.commodityForm.img })
        this.commodityForm.spec_img = this.commodityForm.img
      }

      if (
        Array.isArray(this.commodityForm.info_img) &&
        this.commodityForm.info_img.length !== 0
      ) {
        console.log('详情图是数组不为空')
        // this.fileList2.push({ url: this.commodityForm.info_img })
        this.commodityForm.info_img.forEach((item) => {
          this.fileList2.push({
            url: item,
          })
        })
      } else if (
        typeof this.commodityForm.info_img === 'string' &&
        this.commodityForm.info_img.length !== 0
      ) {
        console.log('详情图是字符串而且不为空')
        let str = this.commodityForm.info_img
        this.commodityForm.info_img = [str]
      } else {
        console.log('图片为空')

        this.fileList2 = []
        this.commodityForm.info_img = []
      }

      if (
        this.commodityForm.slideshow &&
        this.commodityForm.slideshow.length !== 0
      ) {
        this.commodityForm.slideshow.forEach((item) => {
          this.fileList3.push({
            url: item,
          })
        })
      } else {
        this.fileList3 = []
        this.commodityForm.slideshow = []
      }
      // 缓存数据
      setTimeout(() => {
        this.storageItem = lodash.cloneDeep(this.commodityForm.item)
      }, 500)

      this.$forceUpdate()

      // console.log(this.specSizeData);
      // console.log(this.specSizeDataCpyForTable);
      // this.commodityForm.class_id =JSON.parse(res.data.commodity_info.class_id)
    },

    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    async setDel() {
      let info = await getDel({
        mp_id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      //console.log(info)
      if (info.data.code === 1) {
        this.$message.success('删除商品成功')
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message.error(info.data.message)
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {}
      console.log('close')
    },
    //目录接口
    async catalogue() {
      this.tableLoading = true
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
      let info = await getList(this.getInfo)
      this.tableData = info.data.data
      this.exp.count = info.data.exp.count
      this.exp.num = info.data.exp.num
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    // tab点击事件
    handleTabClick() {},
    addCommodityOpen() {
      this.commodityDialogVisible = true
      this.getSupplier()
    },

    // 取消
    cancelAE() {
      this.$refs.commodityFormRef.resetFields()
      this.commodityDialogVisible = false
      this.title = '添加商品'

      this.specSizeData = null
      this.fileList = []
      this.fileList2 = []
      this.fileList3 = []
      this.addOrEdit = 1
      this.checkList = []
      this.activeName = '1'
      this.commodityForm = {
        name: '',
        detail: '',
        for_short: '',
        spec_img: '',
        detail: '',
        info_img: [],
        accessories: 0,
        numbering: '123456',
        consumable: 0,
        v_id: '',
        class_id: [],
        item: [],
        task_id: '',
        differentiate: 2,
        slideshow: [],
        set_meal: [],
        // sort: '',
        meal: [],
      }
      this.modelSpecSize = {
        modelId: '',
        specId: '',
        sizeId: '',
      }
      this.hasType = false

      setTimeout(() => {
        this.$refs.commodityFormRef.clearValidate()
      }, 0)
      this.$forceUpdate()
    },
    // 确认编辑/添加
    confirmAE() {
      this.$refs.commodityFormRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加商品
            if (this.commodityForm.item.length !== 0) {
              // this.commodityForm.item.forEach(ele=>{
              //     this.specIds.forEach(keys=>{
              //         this.commodityForm.item
              //     })
              // })
              for (let i = 0; i < this.commodityForm.item.length; i++) {
                this.commodityForm.item[i]['key'] = this.specIds[i].key
                this.commodityForm.item[i].key_name = this.specTableData[i].spec
              }
            }
            // this.commodityForm.sort = Number(this.commodityForm.sort)
            const query = lodash.cloneDeep(this.commodityForm)

            if (query.meal.length !== 0) {
              query.meal.forEach((ele) => {
                const str = ele.name + ',' + ele.amount
                query.set_meal.push(str)
              })
            }
            delete query['meal']
            if (query.item && query.item.length !== 0) {
              query.item.forEach((ele, index) => {
                ele.meal.forEach((i) => {
                  const str = i.name + ',' + i.amount
                  ele.set_meal.push(str)
                })
              })
            }

            if (query.item && query.item.length !== 0) {
              query.item.forEach((ele) => {
                delete ele['meal']
              })
            }
            query.item = JSON.stringify(query.item)
            const { data: res } = await addCommoditySpec(query)
            console.log(res)

            console.log(res)
            if (res.code === 1) {
              this.$message.success('添加商品成功')
              setTimeout(() => {
                this.$refs.commodityFormRef.clearValidate()
              }, 0)
            } else {
              this.$message.error(res.message)
            }
            this.catalogue()
            this.cancelAE()
          }
          if (this.addOrEdit === 2) {
            // 编辑
            if (this.commodityForm.item) {
              // this.commodityForm.item.forEach(ele=>{
              //     this.specIds.forEach(keys=>{
              //         this.commodityForm.item
              //     })
              // })
              for (let i = 0; i < this.commodityForm.item.length; i++) {
                this.commodityForm.item[i]['key'] = this.specIds[i].key
                this.commodityForm.item[i].key_name = this.specTableData[i].spec
              }
            }
            //  this.commodityForm.spec_img = this.commodityForm.img
            const query = lodash.cloneDeep(this.commodityForm)
            query.set_meal = []
            if (query.meal.length !== 0) {
              query.meal.forEach((ele) => {
                const str = ele.name + ',' + ele.amount
                query.set_meal.push(str)
              })
            }
            delete query['meal']

            query.meal = []
            if (query.item && query.item.length !== 0) {
              query.item.forEach((ele, index) => {
                ele.set_meal = []
                ele.meal.forEach((i) => {
                  const str = i.name + ',' + i.amount
                  ele.set_meal.push(str)
                })
              })
            }

            if (query.item && query.item.length !== 0) {
              query.item.forEach((ele) => {
                delete ele['meal']
              })
            }
            query.item = JSON.stringify(query.item)

            const { data: res } = await editCommoditySpec(query)
            console.log(res)
            if (res.code === 1) {
              this.$message.success('编辑商品成功')
              setTimeout(() => {
                this.$refs.commodityFormRef.clearValidate()
              }, 0)
            } else {
              this.$message.error(res.message)
            }
            this.catalogue()
            this.cancelAE()
          }
        } else {
          this.$message.error('请正确填写表格')
        }
      })
    },
    // 根据id获取规格和规格下的属性
    async getSpecSize(id) {
      const { data: res } = await getEditModelInfo({
        type_id: this.modelSpecSize.modelId,
      })
      // console.log(res.data)
      if (res.data.spec_arr.length !== 0) {
        this.specSizeData = res.data.spec_arr
        this.specSizeDataCpyForTable = lodash.cloneDeep(this.specSizeData)
      }
      this.hasType = true
    },
    addNewInput(row) {
      // this.commodityForm.meal.push({
      //     name:'',
      //     amount:''
      // })
      this.$set(this.commodityForm.meal, this.commodityForm.meal.length, {
        name: '',
        amount: 1,
      })
      this.$forceUpdate()
      // console.log(11111111)
    },
    tableAddNewInput(row, index) {
      //   this.commodityForm.item['set_meal'].push({
      //     amount: '',
      //     name: '',
      //   })
      console.log(this.commodityForm.item[index]['meal'].length)
      this.$set(
        this.commodityForm.item[index]['meal'],
        this.commodityForm.item[index]['meal'].length,
        {
          name: '',
          amount: 1,
        }
      )
      this.$forceUpdate()
    },
    tableDelLastInput(row, index) {
      this.commodityForm.item[index]['meal'].pop()
    },
    delLastInput(row) {
      //console.log(row)
      if (this.commodityForm.meal.length !== 0) {
        this.commodityForm.meal.pop()
        this.$forceUpdate()
      }
    },
    handleIptChange() {
      this.$forceUpdate()
    },
    async onSubmit() {
      if (
        JSON.stringify(this.storageCheckList.sort()) !==
        JSON.stringify(this.checkList.sort())
      ) {
        console.log('选项有改变')
        this.handleSpecChange(this.checkList)
      } else {
        // 没改变
        this.handleSpecChange(this.checkList)
      }
    },
    async reset() {
      if (this.addOrEdit === 1) {
        // 添加
        this.checkList = []
        this.handleSpecChange(this.checkList)
      } else if (this.addOrEdit === 2) {
        // 编辑
        this.checkList = this.storageCheckList
        const { data: res } = await getCommoditySpec({ mp_id: this.rowData.id })
        this.commodityForm = res.data.commodity_info
        delete this.commodityForm.id
        this.commodityForm.meal = []
        this.commodityForm.mp_id = this.rowData.id

        if (res.data.type_info.id) {
          this.modelSpecSize.modelId = res.data.type_info.id

          // console.log(res.data.type_info.spec_arr);
          // 获取规格信息 并处理  使大小颜色等正确勾选
          const specArr = res.data.goods_spec_list
          // console.log(specArr);
          if (specArr.length !== 0) {
            specArr.forEach((i) => {
              const key = i.key
              //console.log(key);
              const tempArr = key.split(',')
              //console.log(tempArr);
              this.checkList.push(...tempArr)

              this.checkList = Array.from(new Set(this.checkList))
            })
            for (let i = 0; i < this.checkList.length; i++) {
              this.checkList[i] = Number(this.checkList[i])
            }
            this.storageCheckList = lodash.cloneDeep(this.checkList)
          }
          // 根据id获取规格和规格下的属性
          this.getSpecSize(res.data.type_info.id).then(() => {
            console.log('123')

            this.handleSpecChange(this.checkList)
            const specList = res.data.goods_spec_list
            this.hasType = true
            this.commodityForm.item = []
            if (specList.length !== 0) {
              specList.forEach((item) => {
                this.commodityForm.item.push({
                  id: item.id,
                  set_meal: item.set_meal,
                  spec_img: item.spec_img,

                  spec: item.key_name,
                  length: item.length,
                  weight: item.weight,
                })
              })
              this.commodityForm.item.forEach((itm) => {
                itm.meal = []
                itm.set_meal.forEach((set) => {
                  let arr = set.split(',')
                  itm.meal.push({
                    name: arr[0],
                    amount: arr[1],
                  })
                })
              })
            }
          })
        }
      }
    },
    // 勾选改变事件
    handleSpecChange(val) {
      //  console.log(val)
      this.handleSpecArrSelect(val)
      // if(this.commodityForm.item.length!==0){
      //     //
      // }
      // 处理tabledata
      //   if(this.commodityForm.item&&this.commodityForm.item.length!==0){
      //     // 已经填写了数据
      //   }
      this.specTableData = []
      this.multipleArr = []
      this.multipleRenderArr = []
      let res1 = []

      let res2 = []
      let ssdcft = JSON.parse(JSON.stringify(this.specSizeDataCpyForTable))
      ssdcft.forEach((ele) => {
        // this.specTableData.push({
        //     spec:`${ele.name}`
        // })
        // 外层循环
        var tempArr = []
        var renderArr = []
        if (ele.item.length !== 0) {
          ele.item.forEach((i) => {
            // console.log(i.flag)
            i.name = ele.name
            i.fid = ele.id
            // 带id的 传后端用
            var str = ''
            // var specKey = []
            // 渲染用
            var renderStr = ''
            if (i.flag) {
              // console.log(this.testSpec);
              str = `${i.name}:${i.item},mid:${i.id}`

              renderStr = `${i.name}:${i.item}`

              tempArr.push(str)
              renderArr.push(renderStr)
            }
            // this.tempArr.push(str)
          })
          // console.log(tempArr)
          this.multipleArr.push(tempArr)
          this.multipleRenderArr.push(renderArr)
        }
      })
      res1 = this.allGroups1(this.multipleArr)
      //console.log(res1)
      this.specIds = []
      if (res1.length !== 0) {
        res1.forEach((item) => {
          // console.log(item.split(','))
          let arr = item.split(',')
          // console.log(arr)
          let tempArray = ''

          arr = arr.filter((i) => {
            if (i.indexOf('mid') !== -1) {
              // 说明找到了id
              return true
            }
          })

          // console.log(arr)
          let test = []
          arr.forEach((i) => {
            i = i.slice(4)
            test.push(i)
          })
          // console.log(test.toString())
          let key = test.toString()
          this.specIds.push({
            key: key,
          })
        })
      }
      res2 = this.allGroups(this.multipleRenderArr)
      //  console.log(res2)
      if (res2.length !== 0) {
        res2.forEach((item) => {
          this.specTableData.push({
            spec: item,
            meal: [],
            set_meal: [],
          })
        })
      }
      this.commodityForm.item = lodash.cloneDeep(this.specTableData)
      if (this.addOrEdit === 2) {
        // 编辑状态
        if (this.storageItem) {
          // 有规格并且有缓存值
          this.commodityForm.item.forEach((ele) => {
            this.storageItem.forEach((i) => {
              if (i.spec === ele.spec) {
                console.log(222)
                ele.weight = i.weight
                ele.length = i.length
                ele.id = i.id
              }
            })
          })
        }
      }
      this.$forceUpdate()
    },
    multi(arr1, arr2) {
      if (arr1.length == 0) {
        // arr1 是空数组 []
        return arr2
      }
      if (arr2.length == 0) {
        return arr1
      }
      let res = []
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          res.push(String(arr1[i]) + ' ' + String(arr2[j]))
        }
      }
      return res
    },
    allGroups(arr) {
      return arr.reduce((total, cur) => {
        let ress = this.multi(total, cur)
        // console.log(ress);
        return ress
      }, [])
    },
    multi1(arr1, arr2) {
      if (arr1.length == 0) {
        // arr1 是空数组 []
        return arr2
      }
      if (arr2.length == 0) {
        return arr1
      }
      let res = []
      for (let i = 0; i < arr1.length; i++) {
        for (let j = 0; j < arr2.length; j++) {
          res.push(String(arr1[i]) + ',' + String(arr2[j]))
        }
      }
      return res
    },
    allGroups1(arr) {
      return arr.reduce((total, cur) => {
        let ress = this.multi1(total, cur)
        // console.log(ress);
        return ress
      }, [])
    },

    // 处理选中的spec_arr
    handleSpecArrSelect(val) {
      // console.log(val);

      this.specSizeDataCpyForTable = lodash.cloneDeep(this.specSizeData)

      const tempVal = JSON.parse(JSON.stringify(val))
      // console.log(tempVal)
      tempVal.forEach((id) => {
        this.specSizeDataCpyForTable.forEach((ele) => {
          // console.log(ele)
          if (ele.item.length !== 0) {
            ele.item.forEach((i) => {
              // console.log(i)
              if (i.id === id) {
                i.flag = true

                console.log('相等')
                // const arr = []

                return true
              } else {
                //return false
              }
            })
          }
        })
      })
    },
    // 处理关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAdd()
          done()
        })
        .catch((_) => {})
    },
    cancelAdd() {
      this.addForm = {}

      this.addDialogVisible = false
    },
    // 确认添加
    confirmAdd() {},
    //打开添加
    async addStorageOpen() {
      const res = await getToday()
      // console.log(res)
      this.addForm.add_time = res.data.data
      const res2 = await getStorageNo()
      // console.log(res2)
      this.getSupplier()
      this.addForm.storage_no = res2.data.data
      const res3 = await getUserInfo()
      this.addForm.purchaser_id = res3.data.data.employee_id
      this.addForm.purchaser_name = res3.data.data.name
      this.addDialogVisible = true
      const res4 = await getWarehousesList()
      // console.log(res4)
      this.warehousesList = res4.data.data
    },
    // 获得供应商下拉菜单
    // async getSupplier () {
    //     const { data: res } = await getOption2()

    //     //console.log(res)
    //     this.supplier = res.data

    // },
    getRowClassName(row) {
      //   console.log(row)
      if (row.row.spec && row.row.spec.length === 0) {
        return 'hidden-expand'
      }
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.commotable >>> .el-form-item {
  margin-bottom: 0px !important;
}
.page {
  margin-top: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}

.border {
  display: flex;
}

.border-item {
  border: 1px solid #eee;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 6px;
  color: #666;
  margin-right: 5px;
}

.active {
  color: #909399 !important;
  background: #f4f4f5 !important;
}

.btns {
  display: flex;
  justify-content: end;
  align-items: center;
}

.spec-checkbox {
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
  padding-top: 20px;
  box-sizing: border-box;
}
.commoTable >>> .hidden-expand .el-table__expand-column .cell {
  visibility: hidden;
}
</style>
